<template>
  <div class="footer" v-if="$beet.options.footer">
    <section class="footer__section">
      <img class="footer__logo" src="/img/logo_2024.png" width="225" height="118" alt="logo"/>
      <h2 class="section__title"> {{ $t('infoTitle') }} </h2>
      <p class="section__p"> {{ $t('infoText') }} </p>
      <form class="footer__subscribe" @submit.prevent="submit">
        <label for="email" style="display: none">Email</label>
        <input :placeholder="$t('yourEmail')" class="input-text" type="text" v-model="form.fields.email.value"
               id="email"/>
        <button class="button -red footer__subscribe-button" :disabled="!form.fields.email.valid || form.sent">{{
            $t('subscribe')
          }}
        </button>
        <Transition name="fade" appear>
          <div v-if="form.message" class="footer__subscribe-confirm">
            {{ form.message }}
          </div>
        </Transition>
      </form>
    </section>

    <section class="footer__section -between">
      <div class="footer__socials">
        <template v-for="social in ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube']" :key="social">
          <a v-if="socials[social]" :href="socials[social]" target="_blank">
            <Icon :id="social"/>
          </a>
        </template>
      </div>

      <div class="footer__card">
        <h3 class="footer__card-title">{{ $beet.options.footer.activitiesTitle }}</h3>
        <template v-for="(i, index) in activities" :key="`activity-${index}`">
          <LangRouterLink v-if="i.activity" :to="{name: $routes.ACTIVITY.SHOW, params: {slug: i.activity.slug ?? 1}}"
                          class="footer__card-line">
            <div>
              <Icon id="star"/>
            </div>
            <div>{{ i.label }}</div>
          </LangRouterLink>
        </template>
        <Bubble class="footer__bubble" :text="socials.bubbleText"/>
      </div>
    </section>
  </div>

  <footer class="footer__bottom" v-if="$beet.options.footer">
    <div class="footer__bottom-credits --vertical">
      <span>{{ `© ${$beet.options.footer.copyright.text}` }}</span>
      <LangRouterLink :to="{name: $routes.POLICY}" class="footer__bottom-policy">
        {{ $beet.options.footer.copyright.confidentiality }}
      </LangRouterLink>
    </div>
    <div class="footer__bottom-credits">
      <p>{{ $t('designed_and_developed') }}</p>
      <a href="https://boitebeet.com/" target="_blank">Beet</a>
    </div>
  </footer>
</template>

<script>
import {Bubble} from '@/components/small'
import {Icon, LangRouterLink} from '@/components/global'
import http from '@/extensions/http'

export default {
  name: 'SiteFooter',
  components: {Bubble, Icon, LangRouterLink},
  inject: ['mq'],
  data() {
    return {
      form: {
        fields: {
          email: {
            value: '',
            valid: false
          }
        },
        sent: false
      }
    }
  },
  computed: {
    activities() {
      if (!this.$beet.options.footer) return []
      return this.$beet.options.footer.activities.map(activity => {
        return {
          activity: this.$beet.activities[Number(activity.activity)],
          label: activity.title
        }
      }, [])
    },
    socials() {
      return this.$beet.options?.contact?.social || {}
    }
  },
  methods: {
    async submit() {
      try {
        this.form.message = null
        this.form.sent = true

        await this.$recaptcha()
        await http.post('/newsletter', {email: this.form.fields.email.value})

        this.form.message = this.$t('newsletter.confirm')

        setTimeout(() => {
          this.form.fields.email = {
            valid: false,
            value: ''
          }
          this.form.sent = false
          this.form.message = null
        }, 5000)
      } catch (e) {
        this.form.message = this.$t('newsletter.error')
        this.form.sent = false
        console.log(e)
      }
    }
  },
  watch: {
    'form.fields.email.value'() {
      this.form.fields.email.valid = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.fields.email.value)
    }
  }
}
</script>
