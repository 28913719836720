<template>
  <div class="header">
    <section class="header__section" v-if="mq.mdPlus && $beet.options.menus">
      <a :href="`https://carnaval.qc.ca/${$i18n.locale === 'en' ? 'en' : ''}`"
         :class="['header__section-back button -back', {'-round' : mq.lgMinus}]">
        <Icon id="arrow-back" :class="{'button__icon' : !mq.lgMinus}"/>
        <span v-if="!mq.lgMinus">{{ $beet.options.menus.menu.backToSite }}</span>
      </a>
      <a v-if="mq.lgPlus && pdf" :href="pdf" target="_blank" class="button -red">
        <Icon id="pdf" class="button__icon --pdf"/>
        <span>{{ $beet.options.menus.menu.pdfVersion || 'PDF' }}</span>
      </a>
    </section>

    <section class="header__section -center">
      <LangRouterLink :to="{name: $routes.HOME.GLANCE}" class="header__logo"
                      style="background-image: url(/img/logo_2024.png)"/>
    </section>

    <section class="header__section" v-if=" $beet.options.menus">
      <a v-if="mq.lgPlus && $beet.options?.contact?.ticketing?.link" :href="$beet.options.contact.ticketing.link" target="_blank" class="button">
        <img src="/img/icons/bonhomme.svg" width="23" height="23" alt="bh" class="button__icon--bonhomme"/>
        <span>{{ $beet.options.menus.menu.buyEffigy }}</span>
      </a>
      <button class="button -round" @click="$i18n.locale = otherLang.toLowerCase()">{{ otherLang }}</button>
      <button class="header__burger" @click="menuOpen = !menuOpen" v-if="mq.mdMinus">
        <Transition name="fade-fast" mode="out-in">
          <Icon :id="menuOpen ? `burger-close` : `burger`" :key="menuOpen ? `burger-close` : `burger`"/>
        </Transition>
      </button>
    </section>
  </div>
  <div v-if="$beet.options?.menus" :class="['header__menu', {'-open' : menuOpen}]">
    <div class="header__menu__buttons">
      <a v-if="pdf" :href="pdf" target="_blank" class="button -red">
        <Icon id="pdf" class="button__icon --pdf"/>
        <span>{{ $beet.options.menus.menu.pdfVersion || 'PDF' }}</span>
      </a>
      <a v-if="$beet.options?.contact?.ticketing?.link" :href="$beet.options.contact.ticketing.link" target="_blank" class="button">
        <img src="/img/icons/bonhomme.svg" width="20" height="20" alt="bh" class="button__icon--bonhomme -sm"/>
        <span>{{ $t('buy_your_badge') }}</span>
      </a>
    </div>

    <h3 class="header__menu__subtitle">{{ $t('schedule') }}</h3>

    <div class="header__menu__links">
      <LangRouterLink @click="menuOpen = false" :to="{name: $routes.HOME.GLANCE}" class="header__menu__link"
                      :class="{'-inverse': ['home.glance', 'site.show', 'home.glance:en', 'site.show:en'].includes($route.name)}">
        {{ $beet.options.menus.home.quickLook }}
      </LangRouterLink>
      <LangRouterLink @click="menuOpen = false" :to="{name: $routes.HOME.ACTIVITIES}" class="header__menu__link"
                      :class="{'-inverse': ['home.activities', 'activity.show', 'home.activities:en', 'activity.show:en'].includes($route.name)}">
        {{ $beet.options.menus.home.allActivities }}
      </LangRouterLink>
      <LangRouterLink @click="menuOpen = false" :to="{name: $routes.HOME.ABOUT}" class="header__menu__link"
                      :class="{'-inverse': ['home.about', 'home.about:en'].includes($route.name)}">
        {{ $beet.options.menus.home.eatDrink }}
      </LangRouterLink>
      <div class="header__menu__link" @click="scrollToBottom">{{ $t('to_watch_for') }}</div>
    </div>

    <div v-if="socials" class="header__menu__socials">
      <template v-for="social in ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube']" :key="social">
        <a v-if="socials[social]" :href="socials[social]" target="_blank">
          <Icon :id="social"/>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/global'

export default {
  name: 'SiteHeader',
  components: {Icon, LangRouterLink},
  inject: ['mq'],
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    scrollToBottom() {
      this.menuOpen = false
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    otherLang() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
    },
    pdf() {
      return this.$beet.options?.menus?.menu?.pdfFile?.original ?? null
    },
    socials() {
      return this.$beet.options?.contact?.social || {}
    }
  }
}
</script>
