<template>
  <div v-if="text" class="banner">
    <div class="banner__scroll">
      <span>{{ text }}</span>
    </div>
    <div class="banner__scroll">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerAlert',
  inject: ['mq'],
  computed: {
    text() {
      return this.$beet.options?.message?.banner
    }
  },
  watch: {
    'text'() {
      if (this.text) {
        document.body.classList.add('--alert')
      }
    }
  }
}
</script>
