import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'
import ROUTES from '@/config/ROUTES'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        name: ROUTES.HOME.BASE,
        component: () => import('@/views/Home.vue'),
        children: [
            {
                path: '/',
                name: ROUTES.HOME.GLANCE,
                component: () => import('@/views/Home/Glance.vue')
            },
            {
                path: '/activites',
                paths: {en: '/activities'},
                name: ROUTES.HOME.ACTIVITIES,
                component: () => import('@/views/Home/Activities.vue')
            },
            {
                path: '/boire-et-manger',
                paths: {en: '/drink-and-eat'},
                name: ROUTES.HOME.ABOUT,
                component: () => import('@/views/Home/About.vue')
            },
        ]
    },
    {
        path: '/activites/:slug',
        paths: {en: '/activities/:slug'},
        name: ROUTES.ACTIVITY.SHOW,
        component: () => import('@/views/Activity/Show.vue')
    },
    {
        path: '/sites/:slug',
        name: ROUTES.SITE.SHOW,
        component: () => import('@/views/Site/Show.vue')
    },
    {
        path: '/politique',
        paths: {en: '/privacy-policy'},
        name: ROUTES.POLICY,
        component: () => import('@/views/Policy.vue')
    },
    {
        path: '/404',
        name: ROUTES.NOT_FOUND,
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

export default router
