<template>
  <div id="mapId" class="geo-map" style="height: 748px; width: 100%; z-index: 0"/>
</template>

<script>
export default {
  name: 'GeoMap',
  inject: ['mq'],
  data() {
    return {
      map: null,
      markers: []
    }
  },
  mounted() {
    this.setupLeaflet()
    this.setupMarkers()
  },
  computed: {
    sites() {
      return this.$beet.sites || {}
    }
  },
  methods: {
    setupLeaflet() {
      const boundX = L.latLng(46.5, -71.8)
      const boundY = L.latLng(47.2, -70.8)
      const bounds = L.latLngBounds(boundX, boundY)
      const mapview = this.mq.mdPlus ? [46.8156, -71.2407] : [46.8120, -71.2141]
      this.map = L.map('mapId', {zoomControl: false})
      this.map.setView(mapview, 14)
      this.map.setMaxBounds(bounds)
      this.map.setMinZoom(12)
      this.map.setZoom(14)

      const mtLayer = L.maptilerLayer({
        apiKey: 'aMB63CpalDpZyuXeoCiA',
        style: '56f1014d-418a-4f2b-9786-6a3a360041f3', // optional
      }).addTo(this.map)
      return mtLayer
    },

    setupMarkers() {
      Object.values(this.sites).forEach(site => {
        const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.55 49.63">
          <defs>
            <style>
              .cls-1 {
                fill: ${site.color};
                stroke-width: 0px;
              }
            </style>
          </defs>
          <path class="cls-1" d="m40.55,20.1v.03c0,18.57-19.72,29.2-19.92,29.31l-.38.19-.36-.22c-.2-.12-5.02-3.05-9.91-8.11C3.45,34.54,0,27.22,0,20.13.48,6.29,10.85,0,20.28,0c4.72,0,9.66,1.57,13.49,4.87,3.83,3.3,6.54,8.33,6.78,15.23Z"/>
        </svg>
        `
        const iconUrl = 'data:image/svg+xml;base64,' + btoa(svg)
        const icon = L.icon({
          iconUrl: iconUrl,
          iconSize: [32, 32]
        })

        const lat = site.geolocation.lat
        const lng = site.geolocation.long
        const marker = L.marker([lat, lng], {icon: icon, siteId: site.id})
            .addTo(this.map)
            .bindPopup(`${site.name}`)

        marker.on('click', () => {
          this.$router.push({name: this.$routes.SITE.SHOW, params: {slug: site.slug ?? 1}})
        })

        marker.on('mouseover', function () {
          marker.openPopup()
        })

        marker.on('mouseout', function () {
          marker.closePopup()
        })
      })
    }
  }
}
</script>

