<template>
  <div v-if="$beet.options.menus" class="navbar">
    <LangRouterLink v-for="(link, linkIndex) in links" :to="link.route" class="button -shadow -nav"
                    :class="{'-inverse': link.activeRoutes.includes($route.name)}" :key="`site-nav-${linkIndex}`">
      <Icon :id="link.icon" class="button__icon"/>
      <span>{{ link.label }}</span>
    </LangRouterLink>
  </div>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/global'

export default {
  name: 'SiteNav',
  components: {Icon, LangRouterLink},
  computed: {
    links() {
      return [
        {
          activeRoutes: ['home.glance', 'site.show', 'home.glance:en', 'site.show:en'],
          icon: 'eye',
          label: this.$beet.options.menus.home.quickLook,
          route: {name: this.$routes.HOME.GLANCE},
        },
        {
          activeRoutes: ['home.activities', 'activity.show', 'home.activities:en', 'activity.show:en'],
          icon: 'calendar',
          label: this.$beet.options.menus.home.allActivities,
          route: {name: this.$routes.HOME.ACTIVITIES},
        },
        {
          activeRoutes: ['home.about', 'home.about:en'],
          icon: 'food',
          label: this.$beet.options.menus.home.eatDrink,
          route: {name: this.$routes.HOME.ABOUT},
        },
      ]
    }
  }
}
</script>
