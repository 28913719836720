export default {
    title: `Programmation <br/> du Carnaval 2024`,
    accessibility: 'Accessibilité',
    activity: {
        explore_all: 'Explorer toutes les activités'
    },
    allTypes: 'Tous les types',
    allPlaces: 'Tous les emplacements',
    allDates: 'Toutes les dates',
    back_to_sites: 'Retour aux sites',
    buy_your_badge: 'Acheter votre effigie',
    carnaval_site: 'Site carnavalesque',
    designed_and_developed: 'Site conçu et développé par',
    searchActivity: 'Rechercher une activité',
    noresultTitle: 'Aucun résultat ne correspond à votre recherche',
    noresultText: 'Veuillez supprimer ou modifier des filtres ou généraliser votre recherche.',
    subscribe: 'M\'abonner',
    yourEmail: 'Votre adresse courriel',
    resultsof : 'résultats sur',
    loadMore: 'Charger plus d’activités',
    infoTitle: 'Inscrivez-vous à notre infolettre pour ne rien manquer!',
    infoText: 'Recevez les dernières nouveautés à l’approche du Carnaval et obtenez des informations privilégiées sur nos offres partenaires.',
    newsletter: {
        confirm: 'Merci, votre inscription a bien été reçue.',
        error: 'Une erreur est survenue, veuillez réessayer ultérieurement.'
    },
    event: {
        back: 'Retour aux activités',
        effigy: 'Effigie requise',
        share: 'Partager l’activité',
        details: 'Obtenir plus de détails',
        partners: 'PARTENAIRES',
        accessibility: 'ACCESSIBILITÉ',
        likeAlso: 'Vous aimerez aussi',
        discover: 'Découvrez les nouvelles activités inusitées qui brisent les conventions et redécouvrez vos événements chouchous. Pour toute la famille et entre amis, en journée comme en soirée, venez festoyez avec nous!',
        explore: 'Explorer toutes les activités'
    },
    gdpr: {
        banner: {
            title: 'Notre utilisation des témoins (cookies)',
            description: 'Nous utilisons des témoins (cookies) afin d’améliorer votre expérience de navigation et personnaliser le contenu de ce site internet. En cliquant sur Accepter, vous nous permettez d’améliorer la façon dont le site est construit.',
            accept: 'Accepter',
            settings: 'Modifier les préférences'
        },

        settings: {
            title: 'Notre utilisation des témoins (cookies)',
            description: 'Nous utilisons des témoins (cookies) pour faire fonctionner notre site. Nous utilisons aussi des témoins d\'analyse pour nous aider à l\'améliorer au fil du temps. Nous ne définirons pas de témoins à moins que vous ne les activiez. L\'utilisation de cet outil installera un témoin sur votre appareil pour mémoriser vos préférences. Pour plus d\'informations sur les témoins que nous utilisons, consultez notre page Énoncé de confidentialité.',
            essentials: {
                title: 'Témoins essentiels',
                description: 'Les témoins essentiels activent des fonctionnalités de base telles que la sécurité, la gestion du réseau et l\'accessibilité. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web.'
            },
            analytics: {
                title: 'Fichiers de témoins d’analyse',
                description: 'Nous aimerions définir des fichiers témoins Google Analytics pour nous aider à améliorer notre site Web en collectant et en rapportant des informations sur la façon dont vous l\'utilisez. Les fichiers témoins collectent des informations de manière complètement anonyme. Pour plus d\'informations sur le fonctionnement de ces fichiers témoins, veuillez consulter notre page Énoncé de confidentialité.',
            },
            save: 'Enregistrer et fermer'
        },
    },
    partners: 'Partenaires',
    schedule: 'Programmation',
    show_on_map: 'Afficher le site sur la carte',
    site_events: 'Événements phares du site',
    top: 'Haut',
    to_watch_for: 'À surveiller',
    view_prog: 'Jeter un coup d’oeil à la programmation'
}
