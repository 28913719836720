<template>
  <SiteHeader/>
  <SiteAlert/>
  <MobileScrollUp/>
  <DailyModal/>
  <SiteNav v-if="mq.mdPlus"/>
  <router-view v-if="$beet.isReady" v-slot="{ Component }" style="">
    <transition name="fade-fast" mode="out-in" appear>
      <div :key="$route.matched[0]?.name === 'main' ? 'main' : $route.name" class="app">
        <component :is="Component"/>
      </div>
    </transition>
  </router-view>
  <Transition name="fade-fast">
    <Loader v-if="!$beet.isReady"/>
  </Transition>
  <SiteFooter/>
  <Gdpr/>
  <div class="theme__gradient"></div>
</template>

<script>
import {useLangRouter} from '@/extensions/langRouter'
import {DailyModal} from '@/components/modals'
import {SiteHeader, SiteNav} from '@/components/navigation'
import {SiteAlert, SiteFooter} from '@/components/sections'
import {Loader, MobileScrollUp} from '@/components/small'
import Gdpr from '@/components/gdpr/Gdpr.vue'

export default {
  name: 'App',
  inject: ['mq'],
  setup() {
    useLangRouter()
  },
  components: {DailyModal, Gdpr, Loader, MobileScrollUp, SiteAlert, SiteHeader, SiteFooter, SiteNav},
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    },
    $route: {
      handler(to, from = {}) {
        const delay = to.name !== from.name ? 250 : 100
        setTimeout(() => {
          const el = to.hash ? document.querySelector(to.hash) : null
          el ?
              el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
              window.scrollTo({top: 0, behavior: 'smooth'})
        }, delay)
      },
      immediate: true
    }
  }
}
</script>
