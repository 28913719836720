<template>
  <div v-if="text" class="bubble" :class="{'-reverse' : reverse, '-dark' : dark, '-light' : light}">
    <div class="bubble__content">
      <Icon :id="icon" class="bubble__content-icon"/>
      <span :class="['bubble__content-text', {'--outside': outside}]">{{ text }}</span>
      <img v-if="outside" src="/img/dehors.png" alt="" class="bubble__image"/>
    </div>
  </div>
</template>

<script>
import {Icon} from '@/components/global'

export default {
  name: 'Bubble',
  components: {Icon},
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'calendar'
    },
    light: {
      type: Boolean,
      default: false
    },
    outside: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: null
    }
  }
}
</script>
