<template>
  <aside class="loader__container" id="loader">
    <div class="loader"></div>
  </aside>
</template>

<script>
export default {
  name: 'Loader'
}
</script>