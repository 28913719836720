<template>
  <div class="mobile-scroll-up" @click="scrollUp">
    <Icon id="arrow" class="mobile-scroll-up__icon"/>
    <span>{{ $t('top') }}</span>
  </div>
</template>

<script>
import {Icon} from '@/components/global'

export default {
  name: 'MobileScrollUp',
  components: {Icon},
  methods: {
    scrollUp() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }
}
</script>
