export default {
    ACTIVITY: {
        SHOW: 'activity.show'
    },
    HOME: {
        ABOUT: 'home.about',
        ACTIVITIES: 'home.activities',
        BASE: 'home',
        GLANCE: 'home.glance'
    },
    NOT_FOUND: 'not_found',
    POLICY: 'policy',
    SITE: {
        SHOW: 'site.show'
    }
}